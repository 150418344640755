// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-media-js": () => import("./../src/pages/media.js" /* webpackChunkName: "component---src-pages-media-js" */),
  "component---src-pages-merch-js": () => import("./../src/pages/merch.js" /* webpackChunkName: "component---src-pages-merch-js" */),
  "component---src-pages-payment-successful-js": () => import("./../src/pages/payment-successful.js" /* webpackChunkName: "component---src-pages-payment-successful-js" */),
  "component---src-pages-success-js": () => import("./../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */)
}

